.fui-checkbox {
  --fui-check-label-width: 2rem;
  --fui-check-label-height: 2rem;
  --fui-check-label-padding-left: 3rem;
  --fui-check-font-size: 1rem;
  --fui-check-check-bg-size: 12px;
  font-family: Roboto;
  color: #27458d;
  font-weight: 500;
}

.fui-checkbox label {
  padding-top: 0.5rem;

  @include lg-down {
    font-size: 16px;
    line-height: 16px;
  }
}

.fui-radio label {
  display: flex;
}

.fui-checkbox input:checked + label:before {
  background-color: rgba(39, 69, 141, 1);
}

.fui-input {
  --fui-border-radius: 0;
  --fui-input-border: none;
  --fui-input-padding: 20px 24px;
  font-weight: 500;
}

.fuit-input::placeholder {
  color: #7d8cb0;
}

.button-hidden {
  .fui-next {
    display: none;
    visibility: hidden;
  }
}