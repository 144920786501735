.header {
    $self: &;
    width: 100%;
    display: block;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    @include xl-up {
        position: relative;
    }
    &__mobile-toggle {
        background-color: #ffffff;
        padding: 1rem;
    }
    &__branding {
        display: flex;
        align-items: center;
    }
    .list, .nav {
        list-style: none;
        display: flex;
        margin: 0 -.5rem;
        &__item {
            margin: 0 .5rem;
            display: flex;
            align-items: center;
            position: relative;
            &:hover {
                a {
                    color: var(--color-brand-primary);
                    &.s-btn {
                        color: #ffffff;
                        &--light {
                            color: var(--color-neutral-secondary);
                        }
                    }
                }
            }
            a {
                display: flex;
                align-items: center;
            }
            .s-form-item-wrap__icon {
                display: flex;
                height: 100%;
                align-items: center;
            }
            &--has-children {
                &::after {
                    display: block;
                    position: relative;
                    content: url('../img/icons/chevron.svg');
                    width: 1.5rem;
                    height: 1.5rem;
                }
                .tooltip {
                    position: absolute;
                    display: none;
                    transform: translateX(-25%);
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 200ms ease-out;
                    top: 3rem;
                    left: 0;
                    padding: 1.5rem 0 0 0;
                    &__inner {
                        background-color: #ffffff;
                        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
                        padding: 1rem;
                        
                    }
                    &:after {
                        display: block;
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        border: .75rem solid transparent;
                        border-bottom-color: #ffffff;
                        top: -0rem;
                        right: 1.25rem;
                    }
                    .tooltip-nav {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        &__item {
                            margin: .5rem 0;
                            display: block;
                            a {
                                white-space: nowrap;
                            }
                        }
                    }
                }
                &:hover {
                    .tooltip {
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        transition: opacity 200ms ease-out;
                        .tooltip-nav__item {
                            a {
                                color: var(--color-neutral-primary);
                            }
                            &:hover{
                                a {
                                    color: var(--color-brand-primary);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__top {
        background-color: #F8FAFF;
        color: var(--color-neutral-primary);
        padding: 1rem 0;
        font-size: .875rem;
        display: none;
        @include xl-up {
            display: block;
        }
    }
    &__main {
        background-color: var(--color-brand-secondary);
        .container {
            @include xl-down {
                padding-right: 0;
            }
        }
        @include xl-up {
            padding: 1.5rem 0;
        }
        .nav {
            display: none;
            @include xl-up {
                display: flex;
            }
        }
    }
    &__bottom {
        background-color: #ffffff;
        color: var(--color-neutral-primary);
        display: none;
        width: 100%;
        height: 3.5rem;
        top: -4rem;
        transition: top 120ms ease-out;
        font-size: .875rem;
        @include lg-up {
            font-size: 1rem;
        }
        @include xl-up {
            display: block;
        }
        .icon-bar {
            width: 7.5rem;
            display: flex;
            margin-left: 1.5rem;
            display: none;
            &__item {
                width: 33.33%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;
                &--red {
                    background-color: var(--color-brand-primary);
                    color: #ffffff;
                }
                &--blue {
                    background-color: var(--color-brand-secondary);
                    color: #ffffff;
                }
            }
            &__item-inner {
                display: block;
                text-align: center;
                width: 100%;
            }
            &__icon {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .product-range-btn {
        background-color: var(--color-brand-primary);
        padding: 1rem 3rem 1rem 1.5rem;
        color: #ffffff;
        font-weight: 700;
        transition: background-color 120ms ease-in-out;
        position: relative;
        display: flex;
        align-items: center;
        &__icon {
            position: absolute;
            right: 1rem;
            top: 1.4rem;
        }
        &:hover {
            cursor: pointer;
            background-color: #b11a2b;
            transition: background-color 120ms ease-in-out;
        }
    }
    .mobile-nav-items {
        display: flex;
        .product-range-btn {
            padding: 1rem 1.5rem;
        }
        @include xl-up {
            display: none;
        }
    }
    &.sticky {
        .header__bottom {
            position: fixed;
            top: 0;
            transition: top 250ms ease-in-out;
            .icon-bar {
                display: flex;
            }
        }
        margin-bottom: 3.5rem;
    }
}

.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #01081B;
    z-index: 3000;
    opacity: 0;
    top: 0;
    transition: opacity 70ms ease-out;
    visibility: hidden;
}

.floating-sidebar {
    position: fixed;
    left: -350px;
    top: 0;
    height: 100%;
    padding: 4rem 2rem;
    background: #ffffff;
    width: 350px;
    max-width: 80%;
    z-index: 3001;
    overflow-y: auto;
    transition: left 130ms ease-in-out;
    ul {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: block;
            padding: .5rem 0;
        }
    }
}

.overlay-nav {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: var(--color-brand-secondary);
    left: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3000;
    transition: left 130ms ease-in-out;
    color: #ffffff;
    padding: 3rem 0;
    font-size: 1.5rem;
    overflow-y: scroll;
    .headline {
        color: #ffffff;
    }
    ul {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: block;
            padding: .5rem 0;
        }
    } 
    &__item {
        font-family: "akagi-pro",sans-serif;
        font-weight: 700;
        a {
            padding: .5rem 6rem .5rem 2rem;
        }
        &--has-sub {
            
            a {
                padding: .5rem 4rem .5rem 2rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                position: relative;
                width: 100%;
                display: block;
                font-weight: 700;
                &::after {
                    content: url('../img/icons/chevron_white.svg');
                    display: block;
                    position: absolute;
                    right: 2rem;
                    top: .5rem;
                    height: 2rem;
                    width: 2rem;
                    transition: all .25s;
                }
            }
            .sub-nav {
                max-height: 0;
                padding: 0;
                transition: all .25s;
                visibility: hidden;
                overflow: hidden;
                font-family: "akagi-pro",sans-serif;
                background-color: #506AA8;
                a {
                    font-weight: 900;
                    padding: 0;
                    &::after {
                        content: "";
                        display: none;
                    }
                    width: 100%;
                    display: block;
                }
            }
            &.active {
                a {
                    overflow: visible;
                    &::after {
                        transform: rotate(180deg);
                        transition: all .25s;
                    }
                }
                .sub-nav {
                    max-height: 100vh;
                    padding: 2rem;
                    transition: all .25s;
                    visibility: visible;
                    overflow: visible;
                }
            }
        }
    }
    &.active {
        transition: left 130ms ease-in-out;
        left: 0;
    }
    &__close {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
    &__inner {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
    }
    &__content {
        width: 100%;
    }
    &--search {
        .s-btn {
            display: block;
            width: 100%;
            margin-top: 1rem;
            text-align: center;
        }
        .s-form-item--search {
            width: 100%;
            padding: 1.5rem;
        }
        .headline--1 {
            text-align: center;
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        .overlay-nav__close {
            right: calc(50% - 2rem);
            top: 4rem;
            svg {
                width: 4rem;
                height: 4rem;
            }
        }
    }
}

body.with-sidebar {
    .floating-sidebar {
        left: 0;
        transition: left 130ms ease-in-out;
    }
    .overlay {
        visibility: visible;
        opacity: .5;
        transition: opacity 70ms ease-out;
    }
}

.toolbar {
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 2000;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 2px solid #ffffff;
    @include xl-up {
        display: none;
    }
    &__item {
        padding: 1rem 0;
        width: 33.33%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        &--red {
            background-color: var(--color-brand-primary);
            color: #ffffff;
        }
        &--blue {
            background-color: var(--color-brand-secondary);
            color: #ffffff;
        }
    }
    &__item-inner {
        display: block;
        text-align: center;
        width: 100%;
    }
    &__icon {
        display: block;
        margin: 0 auto;
    }
}