.hero__slider-wrap .slider__item::before {
opacity: 0.75;
}

.hero__slider-wrap .slider__item .slider__info .headline {
width: 60%;
}

.hero__slider-wrap .slider {
height: 400px;
}





@media screen and (min-width: 960px) {
    .hero {
        padding: 0 0 2rem 0;
    }
}


.news__title {
    background-color: var(--color-bg-base);
    padding: 2rem 0 8rem 0;
}

@media screen and (min-width: 960px) {
    .page-header .thumbnail {
        margin: 1rem auto 0 auto; /*verlaagd naar 1rem */
    }
}