
@mixin selection($background, $text){

    /* WebKit/Blink Browsers */
    ::selection {
      background: $background; 
      color: $text;
    }
    
    /* Gecko Browsers */
    ::-moz-selection {
      background: $background; 
      color: $text;
    }

}

$break-sm: 576px;
$break-md: 768px;
$break-lg: 960px;
$break-xl: 1200px;
$break-xxl: 1600px;

@mixin sm-down {
    @media (max-width: $break-sm) { @content; }
}
@mixin md-down {
    @media (max-width: $break-md) { @content; }
}
@mixin lg-down {
    @media (max-width: $break-lg) { @content; }
}
@mixin xl-down {
    @media (max-width: $break-xl) { @content; }
}
@mixin xxl-down {
    @media (max-width: $break-xxl) { @content; }
}

@mixin sm-up {
    @media (min-width: $break-sm) { @content; }
}
@mixin md-up {
    @media (min-width: $break-md) { @content; }
}
@mixin lg-up {
    @media (min-width: $break-lg) { @content; }
}
@mixin xl-up {
    @media (min-width: $break-xl) { @content; }
}
@mixin xxl-up {
    @media (min-width: $break-xxl) { @content; }
}

@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}