.page-header {
    padding: 7rem 0 0 0;
    @include lg-up {
        padding: 4rem 0 0 0;
    }
    .thumbnail {
        width: 100%;
        max-width: 56rem;
        margin: 2rem auto 0 auto;
        max-height: 25rem;
        position: relative;
        overflow: hidden;
        @include lg-up {
            max-height: 55vh;
            margin: 3rem auto 0 auto;
        }
        &__img {
            width: 100%;
            
        }
        &__arrow {
            position: absolute;
            left: 0;
            top: 0;
            background-color: #27458D;
            color: #EEF1F9;
            padding: 1rem;
            &:hover {
                background-color: #0A2259;
                cursor: pointer;
            }
            @include lg-up {
                padding: 2rem;
            }
        }
    }
}