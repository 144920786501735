// transition for links and buttons
button,a {
  transition: color .15s ease,background-color .15s ease,border-color .15s ease,opacity .15s ease;
}

// default cursor
body{
  cursor:default;
}

// remove blue border from focused elements
*:focus {
  outline:0;
}

.is-desktop {
  // margin-top: 170px;
}

.is-mobile {

}

h1, h2, h3, h4, h5, h6 {
  font-family: "akagi-pro",sans-serif;
  font-style: normal;
  font-weight: 900;
  color: var(--color-neutral-primary);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

.relative {
  position: relative;
}

.headline {
  font-family: "akagi-pro",sans-serif;
  color: var(--color-neutral-primary);
  font-weight: 900;
  &--1 {
    font-size: 1.5rem;
    @include md-up {
      font-size: 2.2rem;
    }
    @include lg-up {
      font-size: 2.8125rem;
    }
  }
  &--2 {
    font-size: 1.25rem;
    @include md-up {
      font-size: 1.5rem;
    }
    @include lg-up {
      font-size: 1.875rem;
    }
  }
  &--3 {
    font-size: 1.5rem;
  }
  &--4 {
    font-size: 1.25rem;
  }
}

.branded {
  color: var(--color-brand-primary);
}

.w {
  &-auto {
    width: auto;
  }
}

.h {
  &-full {
    height: 100%;
    min-height: 100%;
  }
}

.bold, strong {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

a {
  &:hover {
    cursor: pointer;
  }
  &.underlined {
    text-decoration: underline;
  }
}

.s-btn {
  $self: &;
  display: inline-flex;
  padding: 1rem 1.5rem;
  background-color: var(--color-brand-primary);
  color: #ffffff;
  font-weight: 700;
  // border-bottom: 4px solid var(--color-brand-primary-dark);
  &:hover {
    border-color: var(--color-brand-primary);
    #{ $self }__icon-wrap {
      #{ $self }__icon {
        &--right {
          -ms-transform: translateX(100px);
          transform: translateX(100px);
          opacity: 0;
        }
        &--left {
          -ms-transform: none;
          transform: none;
          opacity: 1;
        }
      }
    }
  }
  &--secondary {
    background-color: var(--color-brand-secondary);
  }
  &--light {
    background-color: #ffffff;
    border-color: #D1D1D1;
    color: var(--color-neutral-primary);
    &:hover {
      border-color: #ffffff;
    }
  }
  &--transparent {
    background-color: transparent;
    border-color: none;
    border: none;
    color: var(--color-brand-primary);
    padding-left: 0rem;
    &:hover {
      border-color: none;
    }
  }
  &--icon {
    padding-right: 0;
    #{ $self }__icon-wrap {
      width: 3rem;
      height: 1.4rem;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      #{ $self }__icon {
        position: absolute;
        transition: .4s cubic-bezier(.81,.07,.18,.96);
        opacity: 1;
        &--left {
          -ms-transform: translateX(-60px);
          transform: translateX(-60px);
          opacity: 0;
        }
        &--right {
        }
      }
    }
  }
}

.s-form-item {
  $self: &;
  padding: 1.25rem 1.5rem;
  font-size: 1rem;
  color: var(--color-neutral-primary);
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: none;
  box-shadow: none;
  font-family: inherit;
  &:placeholder {
    color: #80929A;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &--search {
    padding-right: 2.5rem;
    width: 275px;
  }
}

.s-form-item-wrap {
  position: relative;
  &__icon {
    position: absolute;
    right: .75rem;
    top: 0;
    width: 2rem;
    height: 2rem;
    background-image: url('../img/icons/search.svg');
    background-position: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.page-search {
  .s-form-item-wrap {
    &__icon {
      height: 3.625rem;
      width: 3.625rem;
    }
    .s-form-item {
      background-color: var(--color-bg-base);
      border: 1px solid #cfd1d4;
    }
  }
}
.label {
  display: inline-block;
  color: #ffffff;
  padding: .25rem .5rem;
  font-size: .875rem;
  background-color: var(--color-brand-primary);
  &--secondary {
    background-color: var(--color-brand-secondary);
  }
}

figure {
  margin: 0;
}