.news {
  background-color: #ffffff;
  position: relative;
  padding-bottom: 4rem;
  &__title {
    background-color: var(--color-bg-base);
    padding: 4rem 0 8rem 0;
  }
  &__feed {
    width: 100%;
    position: relative;
    margin-top: -6rem;
    .slick-list {
      overflow: visible;
      .slick-track {
        margin: 0 -.5rem;
      }
    }
  }
}