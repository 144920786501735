.card {
    margin: 0 .5rem;
    position: relative;
    &__link {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    &__thumb {
        width: 100%;
        overflow: hidden;
        position: relative;
        padding-bottom: 50%;
        margin-bottom: 1rem;
        .label {
            position: absolute;
            left: 1rem;
            bottom: 1rem;
            z-index: 1;
        }
        .card__img {
            background-size: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            transition: transform 120ms ease-in-out;
        }
    }
    &__meta {
        color: var(--color-brand-primary);
        margin-bottom: .75rem;
    }
    &:hover {
        .card__thumb {
            .card__img {
                transform: scale(1.1);
                transition: transform 120ms ease-in-out;
            }
        }
        cursor: pointer;
    }
}