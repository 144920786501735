.page-work {
    background-color: var(--color-bg-base);
    .work-title {
        display: block;
        padding: 6rem 0 0 0;
        @include xl-up {
            display: flex;
            padding: 2rem 0 0 0;
        }
        &__main {
            width: 100%;
            position: relative;
            padding: 12rem 2rem 4rem 2rem;
            color: #ffffff;
            display: flex;
            align-items: center;
            background-size: cover;
            background-position: center center;
            @include lg-up {
                width: 70%;
                padding: 4rem 2rem;
            }
            .headline, p {
                position: relative;
                color: #ffffff;
            }
            .headline {
                max-width: 350px;
            }
            &:before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(0deg, #27458D 0%, rgba(39, 69, 141, 0) 100%);
                opacity: 0.9;
                @include lg-up {
                    background: linear-gradient(90deg, #27458D 0%, rgba(39, 69, 141, 0) 100%);
                }
            }
        }
        &__sub {
            background-color: var(--color-brand-secondary);
            padding: 1.5rem;
            width: 30%;
            color: #ffffff;
            display: flex;
            align-items: center;
            width: 100%;
            margin: 1rem 0 0 0;
            @include lg-up {
                width: 30%;
                margin: 0 0 0 1rem;
            }
            .headline, p {
                position: relative;
                color: #ffffff;
            }
        }
    }
    .work-usps {
        .work-usp {
            display: block;
            margin: 4rem -.5rem;
            align-items: center;
            @include lg-up {
                display: flex;
            }
            &__content {
                width: 100%;
                order: 1;
                margin-bottom: 2rem;
                padding: 0 .5rem;
                @include lg-up {
                    width: 60%;
                    padding: 0 3rem 0 .5rem;
                    margin-bottom: 0;
                }
            }
            &__img {
                width: 100%;
                order: 2;
                margin-bottom: 2rem;
                padding: 0 .5rem;
                img {
                    width: 100%;
                }
                @include lg-up {
                    width: 40%;
                    padding: 0 .5rem;
                    margin-bottom: 0;
                }
            }
            
            @include lg-up {
                &:nth-child(even) {
                    .work-usp__img {
                        order: 1;
                    }
                    .work-usp__content {
                        order: 2;
                        padding: 0 .5rem 0 3rem;
                    }
                }
            }
        }
    }
    .work-ctas {
        display: block;
        padding: 0 0 4rem 0;
        margin: 0 -.5rem;
        @include lg-up {
            display: flex;
        }
        .block-cta {
            background-color: #ffffff;
            padding: 1.5rem 2rem;
            display: flex;
            align-items: center;
            margin: 1rem .5rem;
            flex-grow: 1;
            flex-basis: 0;
            @include md-up {
                padding: 2.5rem 3rem;
            }
            @include lg-up {
                padding: 3.125rem 4rem;
                margin: 0 .5rem;
            }
            &--secondary {
                background-color: var(--color-brand-secondary);
                color: #ffffff;
                .headline {
                    color: #ffffff;
                }
            }
            &--mobile- {
                &primary {
                    @include lg-down {
                        background-color: #ffffff;
                        color: var(--color-neutral-primary);
                        .headline {
                            color: var(--color-neutral-primary);
                        }
                    }
                }
                &secondary {
                    @include lg-down {
                        background-color: var(--color-brand-secondary);
                        color: #ffffff;
                        .headline {
                            color: #ffffff;
                        }
                    }
                }
                &transparent {
                    @include lg-down {
                        background-color: transparent;
                        color: var(--color-brand-primary);
                        .headline {
                            color: var(--color-brand-primary);
                        }
                    }
                }
            }
            &__inner {
                display: block;
                width: 100%;
            }
        }
    }
}