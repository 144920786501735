.popup {
    position: fixed;
    background-color: #ffffff;
    bottom: 2rem;
    right: 2rem;
    box-shadow: 4px 4px 25px 0px #00000026;
    opacity: 1;
    transition: opacity 300ms ease-in-out;
    z-index: 3;
    &__hidden {
        opacity: 0;
        transition: opacity 300ms ease-in-out;
    }
    &__inner {
        padding: 2.5rem 2rem 1.5rem 2rem;
        position: relative;
    }
    &__title {
        padding-right: 1.5rem;
    }
    &__close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
    }
    @include xl-down {
        width: calc(100% - 2rem);
        bottom: 6rem;
        left: 1rem;
        right: 1rem;
        max-height: calc(100% - 7rem);
        z-index: 20000;
    }
}