// mobile container
.container:not(.touch-full-width){
	padding-left: 3rem;
	padding-right: 3rem;
	max-width: 1440px;
	margin: 0 auto;
	position: relative;
	@include lg-down {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	@include md-down {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.container-fluid {
	padding-right: 2.5rem;
    padding-left: 2.5rem;
}

.container--reading {
	max-width: 720px !important;
}

body, html {
	line-height: 1.5;
	font-weight: 400;
	color: var(--color-neutral-secondary);
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	color: var(--color-neutral-primary);
	scroll-behavior: smooth;
}

body {
	background-color: #ffffff;
	overflow-y: scroll;
	main, .header {
		opacity: 1;
		visibility: visible;
		transition: opacity 600ms ease-out;
		transition-delay: 1400ms;
	}
	.header {
		transition-delay: 200ms;
	}
	&.is-transitioning {
		pointer-events: none;
		overflow: hidden;
		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;  /* IE and Edge */
		  scrollbar-width: none;
		  main, .header {
			opacity: 0;
			transition: opacity 600ms ease-out;
		  }
	}

}

p {
	line-height: 1.6;
	font-size: 18px;
	font-weight: 500;
	margin: 1rem 0;
}

*, div {
	@include font-smoothing(on);
}


.preloader {
	z-index: 3000;
	position: fixed;
	width: 100%;
	height: 100%;
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
	background-color: #202A4A;
	left: 0;
	bottom: 0;
	top: 0;
	transform: scale(1,1);
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffffa6;
	&__logo {
		.bouncing {
			animation-name: bounce;
			animation-duration: 500ms;
			animation-iteration-count: infinite;
		}
	}
}

.width-100 {
	width: 100%;
}