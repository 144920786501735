@font-face {
    font-family: 'P22 Mackinac Pro';
    src: local('P22 Mackinac Pro'), local('P22-Mackinac-Pro'),
        url('../fonts/Mackinac/P22MackinacPro-ExtraBold_12.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Niveau Grotesk';
    src: local('Niveau Grotesk Bold'), local('NiveauGroteskBold'),
        url('../fonts/NiveauGrotesk/NiveauGroteskBold.woff2') format('woff2'),
        url('../fonts/NiveauGrotesk/NiveauGroteskBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Niveau Grotesk';
    src: local('Niveau Grotesk Regular'), local('NiveauGroteskRegular'),
        url('../fonts/NiveauGrotesk/NiveauGroteskRegular.woff2') format('woff2'),
        url('../fonts/NiveauGrotesk/NiveauGroteskRegular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Niveau Grotesk';
    src: local('Niveau Grotesk Light'), local('NiveauGroteskLight'),
        url('../fonts/NiveauGrotesk/NiveauGroteskLight.woff2') format('woff2'),
        url('../fonts/NiveauGrotesk/NiveauGroteskLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');