.page-news,
.page-single,
.page-standard,
.page-job,
.page-standardWide,
.page-acties,
.page-configurator {
  background-color: var(--color-bg-base);
  .block {
    margin: 2rem 0;
    &.mt-0 {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &__cta-with-image {
      height: 100%;
      background-size: cover;
      width: 100% !important;
      position: relative;
      padding: 12rem 2rem 4rem 2rem;
      color: #ffffff;
      display: flex;
      align-items: center;
      background-size: cover;
      background-position: center center;
      @include lg-up {
        width: 70%;
        padding: 4rem 2rem;
      }
      .headline,
      p {
        position: relative;
        color: #ffffff;
      }
      .headline {
        max-width: 440px;
      }
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          0deg,
          #27458d 0%,
          rgba(39, 69, 141, 0) 100%
        );
        opacity: 0.9;
        @include lg-up {
          background: linear-gradient(
            90deg,
            #27458d 0%,
            rgba(39, 69, 141, 0) 100%
          );
        }
      }
    }
    &__header {
      position: relative;
      margin-bottom: 2rem;
      @include lg-up {
        margin-bottom: 6rem;
      }
      .block__header-image {
        width: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        @include lg-up {
          min-height: unset;
        }
      }
      .block__header-inner {
        padding: 4rem 2rem 2rem 2rem;
        position: relative;
        text-align: center;
        color: #ffffff;
        .headline {
          color: inherit;
        }
        @include lg-up {
          padding: 8rem 10rem 5rem 10rem;
        }
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          background: linear-gradient(
            180deg,
            rgba(39, 69, 141, 0) 0%,
            #27458d 78.65%
          );
          opacity: 0.9;
          display: block;
        }
      }
    }
    &__columns {
      max-width: 56rem;
      margin: 0 auto;
      @include lg-up {
        display: flex;
        margin: 0 auto;
      }
      .col {
        flex: 1;
        position: relative;
        width: 100%;
        width: 0;
        background-size: cover;
        background-position: center center;
        display: flex;
        align-items: flex-start;
        flex-basis: 0;
        flex: 1 1 0;
        word-break: break-word;
        width: 100%;
        margin-bottom: 1rem;
        @include lg-up {
          margin: 0 1rem;
        }
        &__content {
          &--text {
            // padding: 2rem;
          }
        }
        // &::after {
        //     content: "";
        //     display: block;
        //     padding-bottom: 100%;
        // }
        // &__content {
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     display: flex;
        //     align-items: center;
        // }
      }
    }
    &__quote {
      background-color: #ffffff;
      width: 100%;
      padding: 3rem;
      color: #27458d;
      .headline {
        color: #27458d;
      }
    }
    &__cta {
      width: 100%;
      padding: 12rem 3rem 3rem 3rem;
      position: relative;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      &.flex-start {
        align-items: flex-start;
      }
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url(../img/svg/overlay.svg);
        background-size: cover;
        background-position: center center;
        display: block;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .block__cta-inner {
        z-index: 1;
        position: relative;
        display: block;
        .headline {
          color: #ffffff;
        }
        .s-btn {
        }
        @include lg-up {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          .headline {
            margin-right: 2rem;
          }
        }
      }
    }
  }
  section.block:last-child {
    padding-bottom: 6rem;
    margin-bottom: 0;
  }
  .block-flex {
    display: block;

    @include lg-up {
      display: flex;
      margin: 0 -0.5rem;
    }
    &__col {
      margin: 1rem 0;
      @include lg-up {
        margin: 0 0.5rem;
      }
      img {
        width: 100%;
      }
    }
  }
  .block-cta {
    background-color: #ffffff;
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    height: 100%;
    &.flex-start {
      align-items: flex-start;
    }
    @include md-up {
      padding: 2.5rem 3rem;
      &--transparent {
        padding: 0 3rem;
      }
    }
    @include lg-up {
      padding: 3.75rem 4.25rem;
      &--transparent {
        padding: 0 4.25rem;
      }
    }
    &--secondary {
      background-color: var(--color-brand-secondary);
      color: #ffffff;
      .headline {
        color: #ffffff;
      }
    }
    &--transparent {
      background-color: transparent;
      @include lg-down {
        padding: 1.5rem 0;
      }
    }
    &--mobile- {
      &primary {
        @include lg-down {
          background-color: #ffffff;
          color: var(--color-neutral-primary);
          .headline {
            color: var(--color-neutral-primary);
          }
        }
      }
      &secondary {
        @include lg-down {
          background-color: var(--color-brand-secondary);
          color: #ffffff;
          .headline {
            color: #ffffff;
          }
        }
      }
      &transparent {
        @include lg-down {
          background-color: transparent;
          color: var(--color-brand-primary);
          .headline {
            color: var(--color-brand-primary);
          }
        }
      }
    }
    &__inner {
      display: block;
      width: 100%;
    }
    &__logo {
      margin-bottom: 1.5rem;
      height: 3rem;
    }
    .usp {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 1rem;
      margin: 1rem 0;
      @include lg-up {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      &__item {
        padding-left: 1.75rem;
        position: relative;
        &::before {
          display: block;
          position: relative;
          width: 1.5rem;
          height: 1.5rem;
          position: absolute;
          left: 0;
          top: 0;
          content: url("../img/svg/check.svg");
        }
      }
    }
  }
}

.page-standardWide {
  padding: 4rem 0 4rem 0;
  @include xl-up {
    padding: 0.1px 0 4rem 0;
  }
}
