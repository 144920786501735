// widths
$external_container_width: 2100px;
$mobile_container: 600px;

// colors
$external_container_background: white;
$main_background: white;

:root {
    --color-neutral-primary: #022372; 

    --color-brand-primary: #E2001A;
    --color-brand-primary-dark: #5D1013;
    --color-brand-secondary: #27458D;

    --color-bg-base: #F2F6FF;
}