.page-jobs {
    background-color: var(--color-bg-base);
    padding-bottom: 4rem;
    .jobs-title {
        padding: 6rem 0 0 0;
        @include lg-up {
            padding: 4rem 0 2rem 0;
        }
    }
    .jobs-wrap {
        margin: 2rem 0;
        .jobs-row {
            margin: 1rem -.5rem 4rem -.5rem;
            display: block;
            @include lg-up {
                display: flex;
                flex-wrap: wrap;
                margin: 1rem -.5rem 3rem -.5rem;
            }
            .job {
                margin: 1rem 0;
                width: 100%;
                @include lg-up {
                    width: 33.33%;
                }
                .card {
                    margin: 2rem .5rem;
                    @include lg-up {
                        margin: 0 .5rem;
                    }
                    &__info {
                        p {
                            display: none;
                            @include lg-up {
                                display: block;
                                margin: 1rem 0 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}