.configurator-html-field {
  color: #27458d;
  font-family: "Roboto";

  a {
    text-decoration: underline;
    text-align: center;
    display: block;
  }

  @include lg-down {
    * {
      font-size: 16px;
    }
  }
}
