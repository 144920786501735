.page-actual, .page-archive, .page-acties {
    background-color: var(--color-bg-base);
    padding-bottom: 4rem;
    position: relative;
    &::before {
        display: block;
        width: 100%;
        height: 22rem;
        background-color: #ffffff;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
    }
    .actual-categories {
        width: 100%;
        background-color: #ffffff;
        padding: 1rem;
        margin: 2rem 0 0 0;
        .actual {
            display: flex;
            margin: 0 -.5rem;
            &__category {
                margin: 0 .5rem;
                &.active {
                    font-weight: 700;
                    color: var(--color-brand-primary);
                }
            }
        }
    }
    .card-row {
        display: block;
        
        @include lg-up {
            display: flex;
            margin: 1rem 0 4rem 0;
        }
    }
    .card-wrap {
        flex-shrink: 0;
        width: 100%;
        padding: 1rem 0;
        @include lg-up {
            width: 33.33%;
        }
    }
    .paginate {
        width: 100%;
        &__next {
            float: right;
        }
    }
}