.footer {
    $self: &;
    background-color: #ffffff;
    position: relative;
    &__break {
        padding: 4rem 0 4rem 0;
        background-color: #ECECEC;
        position: relative;
    }
    &__cursive-title {
        font-family: 'La Belle Aurore', cursive;
    }
    &__clients {
        height: 12rem;
        width: 100%;
        overflow: hidden;
        position: relative;
        &::before,
        &::after {
            background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            content: "";
            height: 100%;
            position: absolute;
            width: 200px;
            z-index: 2;
            display: block;
        }
        
        &::after {
            right: 0;
            top: 0;
            transform: rotateZ(180deg);
        }
        .slider-track {
            animation: scroll 40s linear infinite;
            display: flex;
            width: calc(250px * 16);
            margin-top: 2rem;
            .slide {
                height: 6rem;
                width: 250px;
                padding: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 70%;
                    height: auto;
                }
            }
        }
    }
    &__locations {
        background-color: #0A2259;
        color: #ffffff;
        position: relative;
        padding: 4rem 0;
        .headline {
            color: #ffffff;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            margin-top: .5rem;
            li {
                padding: .5rem 0;
            }
        }
        .locations-wrap {
            width: 100%;
            position: relative;
            display: block;
            margin: 0 -.5rem;
            padding-top: 2rem;
            @include lg-up {
                display: flex;
                padding-top: 0;
            }
        }
        .location {
            position: relative;
            margin: 0 .5rem;
            flex-grow: 1;
            &__thumb {
                width: 100%;
                overflow: hidden;
                position: relative;
                padding-bottom: 50%;
                margin-bottom: 1rem;
                background: #eeeeee;
                display: none;
                background-size: cover;
                background-position: center center;
                @include lg-up {
                    display: block;
                }
            }
            &__info {
                .branded {
                    margin-top: 2rem;
                    display: block;
                }
                .headline {
                    margin: .75rem 0 .5rem 0;
                }
                .list-title {
                    margin-top: 3.5rem;
                    display: block;
                }
            }
        }
        .newsletter {
            padding: 2rem;
            background-color: #27458D;
            width: 100%;
            display: block;
            position: relative;
        }
    }
    &__terms {
        background-color: #04153C;
        padding: 1rem 0 7rem 0;
        color: #818A9D;
        @include lg-up {
            padding: 1rem 0;
        }
        .terms-nav {
            margin-top: 1.5rem;
            @include lg-up {
                margin-top: 0;
            }
            ul {
                list-style: none;
                display: block;
                margin: 0 -.5rem;
                padding: 0;
                @include lg-up {
                    display: flex;
                }
                li {
                    margin: 0 .5rem;
                }
            }
        }
    }
    &__terms-inner {
        display: block;
        text-align: center;
        @include lg-up {
            display: flex;
            justify-content: space-between;
            text-align: left;
        }
    }
    .newsletterForm-form {
        width: calc(200% - 10rem);
        display: flex;
        margin-top: 6rem;
        .fields {
            flex-grow: 1;
        }
        .heading {
            display: none;
        }
        .tab {
            height: 100%;
        }
        .field {
            height: 100%;
        }
        .input {
            width: 100%;
            height: 100%;
            input {
                width: 100%;
                height: 100%;
                border: none;
            }
        }
        .submit {
            display: inline-flex;
            padding: 1rem 1.5rem;
            background-color: var(--color-brand-primary);
            color: #ffffff;
            font-weight: 700;
            cursor: pointer;
        }
        .sproutforms-message-success {
            margin-right: 1rem;
        }
        @include lg-down {
            margin: 1rem 0 2rem 0;
            width: 100%;
        }
    }
}

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 8))}
}

.page-news, .page-actual {
    .footer__break {
        padding: 10rem 0 4rem 0;
    }
}