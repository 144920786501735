.fui-radio input:checked + label:before,
.fui-radio input + label:before {
  background: none;
  content: none;
}

.fui-radio input + label {
  cursor: pointer;
}

.fui-radio input:checked + label {
  box-shadow: inset 0px 0px 0px 3px rgba(39, 69, 141, 1);
  cursor: pointer;
}
