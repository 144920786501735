.hero {
  background-color: var(--color-bg-base);
  padding-bottom: 4rem;
  padding: 4rem 0 0 0;
  @include lg-up {
    padding: 0 0 4rem 0;
  }
  .product-range-sidebar {
    background-color: #ffffff;
    padding: 1rem 0 1rem 0;
    display: none;
    @include lg-up {
        display: block;
    }
    ul {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: block;
        padding: .5rem 1.5rem;
      }
    }
  }
  .sidebar-mini {
    background-color: #ffffff;
    margin-top: 1rem;
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    padding: 1rem 1.5rem;
    overflow: hidden;
    position: relative;
    margin: 1rem .5rem;
    width: 100%;
    flex-shrink: 0;
    @include md-up {
      width: 50%;
      margin: 0 .5rem;
    }
    @include lg-up {
      flex-grow: none;
      flex: none;
    }
    &__link {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
    &__thumb {
      position: absolute;
      width: calc(100% - 2rem);
      height: 8rem;
      left: 1rem;
      top: 1rem;
      background-size: cover;
      background-position: center center;
      transition: transform 120ms ease-in-out;
      background-repeat: no-repeat;
    }
    &__info {
      margin-top: 8rem;
      z-index: 1;
      .headline {
        margin-top: .5rem;
      }
    }
    &:hover {
      cursor: pointer;
      .sidebar-mini__thumb {
        transform: scale(1.1);
        transition: transform 120ms ease-in-out;
      }
    }
  }
  .featured-items {
    display: block;
    width: 100%;
    position: relative;
    &__wrap {
      display: block;
      @include lg-up {
        display: flex;
        margin: 0 -.5rem;
      }
    }
  }
  &__featured-wrap {
    width: 100%;
    margin-top: 1rem;
    display: block;
    @include lg-up {
      margin-top: 0;
      display: flex;
    }
    &--md-down {
      display: block;
      @include lg-up {
        display: none;
      }
    }
    &--lg-up {
      display: none;
      @include lg-up {
        margin: 1rem -.5rem 0 -.5rem;
        width: calc(100% + 1rem);
        display: flex;
      }
    }
    .featured-item-row {
      display: block;
      width: calc(100%);
      margin: 1rem -.5rem;
      @include md-up {
        display: flex;
        width: calc(100% - 1rem);
      }
      @include lg-up {
        margin: 1rem -.5rem;
        width: calc(50% + .5rem);
        &:nth-child(2) {
          margin-left: .5rem;
        }
      }
    }
  }
  &__slider-wrap {
    padding: 1rem 0 0 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @include lg-up {
      padding: 1rem 0 0 1rem;
    }
    .slider {
      $slider: &;
      width: 100%;
      height: auto;
      position: relative;
      flex-grow: 1;
      margin-bottom: 0;
      @include lg-up {
        margin-bottom: 1rem;
      }
      .slick-list, .slick-track {
        height: 100%;
      }
      .slick-dots {
        bottom: 2rem;
        right: 2rem;
        width: auto;
        li {
          width: .75rem;
          height: .75rem;
          border-radius: 50%;
          background-color: rgba(255,255,255,0.45);
          &.slick-active {
            background-color: #ffffff;
          }
        }
      }
      #hero-slider {
        height: 100%;
      }
      &__item {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: flex-end;
        padding: 6rem 2rem 2rem 2rem;
        color: #ffffff;
        background-size: cover;
        background-position: center center;
        @include lg-up {
          padding: 2rem;
        }
        .slider__info {
          z-index: 1;
          .headline {
            color: #ffffff;
          }
        }
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          background-image: url('../img/svg/overlay.svg');
          display: block;
          background-position: center bottom;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
  .featured-item {
    margin: 1rem 0;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: flex-end;
    color: #ffffff;
    padding: 7rem 1.5rem 1rem 1.5rem;
    overflow: hidden;
    @include lg-up {
      flex-grow: 1;
      flex: 1 0 0%;
      padding: 4rem 1.5rem 1rem 1.5rem;
      margin: 0rem .5rem;
    }
    &__link {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
    &__thumb {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-size: cover;
      background-position: center center;
      transition: transform 120ms ease-in-out;
    }
    &__info {
      z-index: 1;
      pointer-events: none;
      .headline {
        color: #ffffff;
        margin-top: .5rem;
      }
    }
    &::after {
      content: "";
      width: 100%;
      height: 80%;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: url('../img/svg/overlay.svg');
      display: block;
    }
    &:hover {
      cursor: pointer;
      .featured-item__thumb {
        transform: scale(1.1);
        transition: transform 120ms ease-in-out;
      }
    }
    &--outlet {
      display: flex;
    }
  }
}