.breadcrumbs {
    .breadcrumb {
        display: flex;
        margin: 0 0 1rem 0;
        &__item {
            color: #ffffff;
            a {
                color: #ffffff;
            }
            &::after {
                display: inline-block;
                content: ' · ';
                position: relative;
                margin: 0 10px 0 5px;
            }
            &.is-active {
                &::after {
                    content: '';
                    margin: 0;
                }
            }
        }
    }
    @include md-down {
        display: none;
    }
}

.text-center {
    .breadcrumbs {
        display: flex;
        justify-content: center;
    }
}