.about {
    background-color: var(--color-bg-base);
    padding: 2rem 0;
    .block-cta-wrap {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1rem;
        @include lg-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
    .block-cta {
        background-color: #ffffff;
        padding: 1.5rem 2rem;
        display: flex;
        align-items: center;
        @include md-up {
            padding: 2.5rem 3rem;
        }
        @include lg-up {
            padding: 3.125rem 4rem;
        }
        &--secondary {
            background-color: var(--color-brand-secondary);
            color: #ffffff;
            .headline {
                color: #ffffff;
            }
        }
        &--mobile- {
            &secondary {
                @include lg-down {
                    background-color: var(--color-brand-secondary);
                    color: #ffffff;
                    .headline {
                        color: #ffffff;
                    }
                }
            }
            &transparent {
                @include lg-down {
                    background-color: transparent;
                    color: var(--color-brand-primary);
                    .headline {
                        color: var(--color-brand-primary);
                    }
                }
            }
        }
        &__inner {
            display: block;
            width: 100%;
        }
        .usp {
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 1rem;
            margin: 1rem 0;
            @include lg-up {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
            &__item {
                padding-left: 1.75rem;
                position: relative;
                &::before {
                    display: block;
                    position: relative;
                    width: 1.5rem;
                    height: 1.5rem;
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: url('../img/svg/check.svg');
                }
            }
        }
    }
}